import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  align-items: center;
`
const DownloadNow = styled.h3`
  color: black;
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  line-height: 1.5em;
`
const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0;
  width: 100%;
  max-height: 200px;
  justify-content: center;
  flex-wrap: wrap;
  a {
    display: flex;
    justify-content: center;
    width: 250px;
    padding: 0 5px;
    img {
      width: 250px;
      object-fit: contain;
    }
  }
  img.first {
    margin: 15px;
    width: 200px;
  }
`
const Download = () => {
  return (
    <Container>
      <DownloadNow>
        Lade die Nspire App jetzt herunter
        <br />
        und entdecke Erlebnisse in deiner Umgebung!
      </DownloadNow>
      <BadgeContainer>
        <a href="https://apps.apple.com/us/app/nspire/id1640098346?itsct=apps_box_badge&amp;itscg=30200">
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1505779200&h=94ec581dfa16d74d0674ccac3d74a3eb"
            alt="Download on the App Store"
            className="first"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=de.nspire&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            alt="Jetzt bei Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
          />
        </a>
      </BadgeContainer>
    </Container>
  )
}

export default Download
