/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ImageContainer = styled.div`
  width: 100vw;
  height: 60vh;
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000;
  position: relative;
  justify-content: center;
  display: flex;
`
const DotContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  padding-bottom: 10px;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`
const Dot = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${props => (props.active ? '#eb674c' : '#fff')};
`
const Images = ({ images }) => {
  const interval = React.useRef(null)
  const [src, setSrc] = useState(images[0].src)

  useEffect(() => {
    if (interval.current) clearInterval(interval.current)
    interval.current = setInterval(() => {
      const index = images.findIndex(image => image.src === src)
      const nextIndex = index + 1 === images.length ? 0 : index + 1
      setSrc(images[nextIndex].src)
    }, 5000)
  }, [images])

  return (
    <ImageContainer src={src}>
      <DotContainer>
        {images.map((image, index) => (
          <Dot key={index} active={image.src === src} />
        ))}
      </DotContainer>
    </ImageContainer>
  )
}

export default Images
