/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import TitleBar from './TitleBar'
import Images from './Images'
import Download from './Download'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
`

const stagingConfig = {
  apiKey: 'AIzaSyBnz7e4KyCDLgaXHzL6Kz657V_dBB_Em80',
  authDomain: 'nspire-staging.firebaseapp.com',
  databaseURL:
    'https://nspire-staging-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'nspire-staging',
  storageBucket: 'nspire-staging.appspot.com',
  messagingSenderId: '495302381630',
  appId: '1:495302381630:web:a1f48d38c90a46c6b9016f',
  measurementId: 'G-15JEEMV5CL'
}
const prodConfig = {
  apiKey: 'AIzaSyCTW2-35k7c4q3MRSGsmWpn1YAa_c0oMnw',
  authDomain: 'nspire-live.firebaseapp.com',
  databaseURL:
    'https://nspire-live-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'nspire-live',
  storageBucket: 'nspire-live.appspot.com',
  messagingSenderId: '776584037297',
  appId: '1:776584037297:web:58c6924031fd9334143390',
  measurementId: 'G-ZGNC0NJXF6'
}

firebase.initializeApp(
  process.env.REACT_APP_STAGING === 'true' ? stagingConfig : prodConfig
)
const db = firebase.firestore()
function App() {
  const [locationGroup, setLocationGroup] = React.useState(null)
  const redirectToLanding = () => {
    window.location.href = 'https://nspire-app.com/'
  }
  const getLocationGroupData = async locationGroupID => {
    const data = await (
      await db.collection('locationGroups').doc(locationGroupID).get()
    ).data()
    if (!data) return redirectToLanding()
    setLocationGroup(data)
  }
  useEffect(() => {
    const locationGroupID = window.location.pathname.split('/')[1]
    if (!locationGroupID) redirectToLanding()
    else getLocationGroupData(locationGroupID)
  }, [])
  if (!locationGroup) return null
  return (
    <Container>
      <ContentContainer>
        <Images images={locationGroup.images} />
        <TitleBar
          title={locationGroup.name}
          goodToKnow={locationGroup.goodToKnow}
        />
      </ContentContainer>
      <Download />
    </Container>
  )
}

export default App
