import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 50px 50px 30px;
  margin: 0;
`

const Title = styled.h1`
  color: black;
  font-size: 2em;
  font-weight: bold;
`
const GoodToKnowTitle = styled.h2`
  margin-top: 20px;
  color: black;
  font-size: 1.5em;
  font-weight: bold;
`
const GoodToKnow = styled.p`
  margin-top: 20px;
  color: black;
  font-size: 1em;
  line-height: 1.5em;
`
const Logo = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: auto;
`
const TitleBar = ({ title, goodToKnow }) => {
  return (
    <Container>
      <Logo src="/logo.png" />
      <Title>{title}</Title>
      {!!goodToKnow && (
        <>
          <GoodToKnowTitle>Gut zu wissen</GoodToKnowTitle>
          <GoodToKnow>{goodToKnow}</GoodToKnow>
        </>
      )}
    </Container>
  )
}

export default TitleBar
